import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import LoaderModal from '../loaderModal'
import { apiHandler } from '../../api/apihandler';
import { MY_TICKET_CANCEL } from "../../api/constants";
import Moment from 'moment';
import Swal from 'sweetalert2'


const MyTicketsCard = ({ elm, eventTicketNos, getMyTicketsList, isPast }) => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
    }, [])

    async function cancelTicketApi() {
        if (localStorage.getItem("uzoneAuthToken")) {
            setShowLoader(true)
            try {
                const res = await apiHandler(MY_TICKET_CANCEL, "POST", {
                    event_id: eventTicketNos?.event_id,
                    ticket_id: eventTicketNos?.id
                    // ticket_order_id: eventTicketNos?.event_ticket_order_id
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: res?.response?.data?.data?.message,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getMyTicketsList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setShowLoader(false)
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    return (
        <>
           <LoaderModal showLoader={showLoader}/>
            <div>
                {
                    isPast ?
                        <Card className="rounded  mb-0 card_read_background_color" >
                            <Card.Body>
                                <div>
                                    <div className="d-flex w-100">
                                        <div className="events-detail ms-0">
                                            <h4><Link to={`/dashboards/app/event-detail/${elm?.event?.slug}`}
                                                onClick={() => {
                                                    localStorage.setItem('eventIsPast', isPast)
                                                }}>{elm?.event?.name}</Link></h4>
                                            <h4 className="mb-1">{eventTicketNos?.ticket_no}</h4>
                                            <h6>{Moment(elm?.event?.start_time).format('DD MMMM YYYY') + " - " + Moment(elm?.event?.end_time).format('DD MMMM YYYY')}</h6>
                                            <h6>{Moment(elm?.event?.start_time).format('hh:mm A') + " - " + Moment(elm?.event?.end_time).format('hh:mm A')}</h6>
                                            <h6>{elm?.event?.city + ", " + elm?.event?.state + ", " + elm?.event?.zip}</h6>
                                            <h4>{"$" + elm?.price}</h4>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card> :
                        eventTicketNos?.status === 1 ?
                            <Card className="rounded  mb-0 " >
                                <Card.Body>
                                    <div className="w-100">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="events-detail ms-0">
                                                {/* <h4><Link to={`/dashboards/app/event-detail/${elm?.event?.slug}`}
                                                    onClick={() => {
                                                        localStorage.setItem('eventIsPast', isPast)
                                                    }}>{elm?.event?.name}</Link></h4> */}
                                                <h4 className="mb-1"><Link to="#">{eventTicketNos?.ticket_no}</Link></h4>
                                                <h6>{Moment(elm?.event?.start_time).format('DD MMMM YYYY') + " - " + Moment(elm?.event?.end_time).format('DD MMMM YYYY')}</h6>
                                                <h6>{Moment(elm?.event?.start_time).format('hh:mm A') + " - " + Moment(elm?.event?.end_time).format('hh:mm A')}</h6>
                                                <h6>{elm?.event?.city + ", " + elm?.event?.state + ", " + elm?.event?.zip}</h6>
                                                <h4>{"$" + elm?.price}</h4>
                                            </div>
                                            <div>
                                                <Link onClick={() => {
                                                    Swal.fire({
                                                        title: 'Warning!',
                                                        text: `Are you sure, want to cancel ${eventTicketNos?.ticket_no} ticket?`,
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        showConfirmButton: true,
                                                        cancelButtonText: 'Cancel',
                                                        confirmButtonText: 'Cancel Ticket',
                                                        confirmButtonColor: "#5c518b"
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            cancelTicketApi()
                                                        }
                                                    })
                                                }}>
                                                    <i className="ri-delete-bin-7-line h4"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> :
                            <Card className="rounded  mb-0 card_read_background_color" >
                                <Card.Body>
                                    <div className="w-100">
                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div className="events-detail ms-0">
                                                <h4><Link to={`/dashboards/app/event-detail/${elm?.event?.slug}`}
                                                    onClick={() => {
                                                        localStorage.setItem('eventIsPast', isPast)
                                                    }}>{elm?.event?.name}</Link></h4>
                                                <h4 className="mb-1">{eventTicketNos?.ticket_no}</h4>
                                                <h6>{Moment(elm?.event?.start_time).format('DD MMMM YYYY') + " - " + Moment(elm?.event?.end_time).format('DD MMMM YYYY')}</h6>
                                                <h6>{Moment(elm?.event?.start_time).format('hh:mm A') + " - " + Moment(elm?.event?.end_time).format('hh:mm A')}</h6>
                                                <h6>{elm?.event?.city + ", " + elm?.event?.state + ", " + elm?.event?.zip}</h6>
                                                <h4>{"$" + elm?.price}</h4>
                                            </div>
                                            <div>
                                                <h6>Cancelled</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                }
            </div>
        </>
    )
}

export default MyTicketsCard
