import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCardForClasses from '../stripe/stripeCardForClasses';


const ClassBuyPopup = ({ elm, showBuy, setShowBuy }) => {
    const stripePromise = loadStripe('pk_test_51LAnCBDvkV73SGhIyJPlSJ5hZ4P92HGd47jfYWpZofysweYVmtzTDZuKEWQZLgHIX8z1mkNcV5vegJAZ76rmflE500j3BgZTQf');
    const [count, setCount] = useState(1);

    const handleClose = () => {
        setShowBuy(false)
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <Modal scrollable={true} show={showBuy} onHide={handleClose} size="md" style={{ marginTop: 100 }}>
                <Modal.Header className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <div className="me-3">
                            <img loading="lazy" className="rounded-circle  avatar-50"
                                src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                        </div>
                        <div className="w-100">
                            <div className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <h5 className="mb-0 d-inline-block"><Link to="#">{elm?.name}</Link></h5>
                                    <p className="mb-0">{elm?.start_date + " - " + elm?.end_date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-secondary lh-1" onClick={handleClose} ><span className="material-symbols-outlined">close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="post-item" style={{ maxHeight: "500px" }}>
                        <Row>
                            <div className="d-flex align-items-center mt-2">
                                <h6>Payment Mode: </h6>
                                <h5>{elm?.class_payment_type === 0 ? "Weekly Payment" : elm?.class_payment_type === 1 ? "Monthly Payment" : "One Time Payment"}</h5>
                            </div>
                            <div className="d-flex align-items-center mt-2">
                                <h6>Payble Amount: </h6>
                                <h5>{"$" + (count * elm?.price)}</h5>
                            </div>
                            <span className="product-price" style={{ fontSize: "16px", marginTop: "20px" }}>Card Details:</span>
                            <div style={{ marginTop: "10px" }}>
                                <Elements stripe={stripePromise}>
                                    <StripeCardForClasses classId={elm?.id} handleClose={handleClose} />
                                </Elements>
                            </div>

                            {/* <button type="submit" className="btn btn-primary mt-5"
                                onClick={() => {
                                }}
                            >Buy Tickets</button> */}
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ClassBuyPopup
