import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Loader from "react-js-loader";
import { apiHandler } from '../../api/apihandler';
import { EVENT_ADD_TO_FAVORITE, EVENT_MEMBERS } from "../../api/constants";
import Moment from 'moment';
import DOMPurify from "isomorphic-dompurify";

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import LoaderModal from '../loaderModal'


const MyClassCard = ({ elm, getClassList, isPast }) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {

    }, [])


    return (
        <>
            {/* <div style={{ width: "33%" }}> */}
            <LoaderModal showLoader={showLoader} />
            <div>
                <Link to={`/dashboards/app/class-detail/${elm?.classes?.slug}`}>
                    <Card className="rounded  mb-0" >
                        {/* <div className="event-images" style={{ display: "contents", position: "relative" }}>
                        <Link to={`/dashboards/app/event-detail/${elm?.slug}`}>
                            <img src={elm?.image ? elm?.image : "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                className="img-fluid" alt="Responsive"
                                style={{ maxHeight: "250px", width: "100%", objectFit: "cover" }} />
                        </Link>
                    </div> */}
                        <Card.Body>
                            <div>
                                <div className="events-detail ms-2">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5><Link to="#">{elm?.classes?.name}</Link></h5>
                                        <h5>{"$" + elm?.classes?.price} {elm?.classes?.class_payment_type === 0 ? "/ W" : elm?.classes?.class_payment_type === 1 ? "/ M" : ""}</h5>
                                    </div>
                                    {/* <h6>{Moment(elm?.start_date).format('d MMM yyyy') + " - " + Moment(elm?.end_date).format('d MMM yyyy')}</h6> */}
                                    <h6>{elm?.classes?.start_date + " - " + elm?.classes?.end_date}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elm?.classes?.description.substring(0, 110) + (elm?.classes?.description?.length > 135 ? "..." : "")) }} />
                                    {/* <h6>Max student: {elm?.max_student}</h6> */}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Link>
            </div>
        </>
    )
}

export default MyClassCard
