import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Image, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../api/apihandler';
import { AD_CLICK, AD_LIST, AD_VIEW } from '../../api/constants'
import Swal from 'sweetalert2'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { InView } from "react-intersection-observer";

const MiddleAdCard = ({ index, adsPageVal, updateAdsPageVal }) => {
    const navigate = useNavigate();
    const [adsList, setAdsList] = useState([]);
    // const [adsMaxPage, setAdsMaxPage] = useState(1);

    useEffect(() => {
        if ((index + 1) % 3 === 0) {
            getMiddleAdsList(adsPageVal)
            // getMiddleAdsList((index + 1) > 3? ((index + 1)/3):1)
        }
    }, []);

    async function getMiddleAdsList(pageVal) {
        try {
            const res = await apiHandler(AD_LIST, "POST", {
                page: pageVal,
                limit: 1,
                position: "2"
            })
            if (res.data?.response?.status === 200) {
                // setAdsMaxPage(res?.data?.data?.userPosts?.meta?.last_page)
                if (pageVal < res?.data?.data?.userPosts?.meta?.last_page) {
                    await updateAdsPageVal(pageVal + 1)
                } else {
                    await updateAdsPageVal(1)
                    adsPageVal(1)
                }
                if (res?.data?.data?.userPosts?.meta?.total > 0) {
                    setAdsList(adsList.concat(res?.data?.data?.userPosts?.data))

                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAdView() {
        try {
            const res = await apiHandler(AD_VIEW, "POST", {
                ad_id: adsList[0]?.id,
                device_type: 0,
            })
            // if (res.data?.response?.status === 200) {
            //     console.log("Ad view working..........")
            // }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAdClick() {
        try {
            const res = await apiHandler(AD_CLICK, "POST", {
                ad_id: adsList[0]?.id,
                device_type: 0,
            })
            // if (res.data?.response?.status === 200) {

            // }
        } catch (error) {
            console.error(error);
        }
    }

    const loadView = (inView) => {
        if (inView) {
            getAdView()
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="post-item">
                        {/* <div className="user-post-data pb-3">
                            <div className="d-flex justify-content-between">
                                <div className="me-3">
                                    <img loading="lazy" className="rounded-circle  avatar-45"
                                        src={adsList[0]?.user?.user_setting?.photo ? adsList[0]?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                </div>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div>
                                            <h5 className="mb-0 d-inline-block"><Link to={profileData?.user_type === 4 ? "#" : `/dashboard/app/friend-profile/${adsList[0]?.user?.slug}`}>{adsList[0]?.user?.first_name + " " + adsList[0]?.user?.last_name}</Link></h5>
                                            <p className="mb-0">{Moment(adsList[0]?.created_at).format('DD MMMM YYYY')}</p>
                                        </div>
                                        <div className="card-post-toolbar">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="bg-transparent">
                                                    <span className="material-symbols-outlined">
                                                        more_horiz
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className=" m-0 p-0">
                                                    {
                                                        adsList[0]?.is_own === 1 ?
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top"
                                                                    onClick={() =>
                                                                        Swal.fire({
                                                                            title: 'Warning!',
                                                                            text: 'Are you sure, want to delete this feed?',
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            showConfirmButton: true,
                                                                            cancelButtonText: 'Cancel',
                                                                            confirmButtonText: 'Confirm',
                                                                            confirmButtonColor: "#5c518b"
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                getFeedDelete(adsList[0]?.id)
                                                                            }
                                                                        })}>
                                                                    <i className="ri-delete-bin-7-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Delete</h6>
                                                                        <p className="mb-0">Remove this Post from your profile</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item> : null
                                                    }
                                                    <Dropdown.Item className=" p-3" to="#">
                                                        <div className="d-flex -top">
                                                            <i className="ri-flag-2-line h4"></i>
                                                            <div className="data ms-2">
                                                                <h6>Report Flag</h6>
                                                                <p className="mb-0">Flag reason for this post .</p>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="d-flex justify-content-between flex-wrap">
                            <div>
                                {
                                    adsList[0]?.user_post_ad?.title ?
                                        <div className="mt-0">
                                            <h5><Link to="#">{adsList[0]?.user_post_ad?.title}</Link></h5>
                                        </div> : null
                                }
                                {
                                    adsList[0]?.content ?
                                        <div className="mt-0">
                                            <h6>{adsList[0]?.content}</h6>
                                        </div> : null
                                }
                            </div>
                        </div>

                        <div className="user-post">
                            <Link to={adsList[0]?.user_post_ad?.ad_link} target='_blank noopener,noreferrer' onClick={() => getAdClick()}>
                                <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                    <Masonry columnsCount={3} gutter="10px">
                                        {adsList[0]?.user_post_images.map((image, i) => (
                                            // image?.media_type === 1 ?
                                            //     <div key={image?.id}>
                                            //         <div className="img-fluid" style={{ position: "relative" }} >
                                            //             <img loading="lazy"
                                            //                 src={image?.thumb} alt="gallary" className="img-fluid" />
                                            //         </div>
                                            //         <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                            //             <img style={{ height: 50, width: 50 }} src={playButton} />
                                            //         </Link>
                                            //     </div> :
                                            <img key={image?.id} loading="lazy" src={image?.image_path}
                                                alt="post" className="img-fluid w-100" />
                                        ))}
                                    </Masonry>
                                </ResponsiveMasonry>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-2">
                            <div>
                                {
                                    adsList[0]?.user_post_ad?.description ?
                                        <div className="mt-0">
                                            <h6>{adsList[0]?.user_post_ad?.description}</h6>
                                        </div> : null
                                }
                            </div>
                            <div>
                                <Link to={adsList[0]?.user_post_ad?.ad_link} target='_blank noopener,noreferrer' onClick={() => getAdClick()}>
                                    <button type="submit" className="btn btn-primary d-block w-20">
                                        {adsList[0]?.user_post_ad?.button_text}</button>
                                </Link>
                            </div>
                        </div>
                        {/* <div className="comment-area mt-4">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="like-block position-relative d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h6 className="mb-0 d-inline-block" style={{ marginLeft: "10px" }}>
                                                <Link to="#"
                                                >{adsList[0]?.comment_count + " "}Views</Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </Card.Body>
            </Card>
            <InView as="div" onChange={(inView, entry) => loadView(inView)} />
        </>
    )
}

export default MiddleAdCard