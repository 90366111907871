import AWS from "aws-sdk";
import { Buffer } from "buffer";

Buffer.from("anything", "base64"); 

//window.Buffer = window.Buffer || require("buffer").Buffer;


   const handleImageUpload = async (selectFile, fileKey, fileType) => {
    //The Friend In Need Bucket details

    var S3_KEY="AKIA537P53RJOXRQHG5V"
    var S3_SECRET="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"
    var S3_BUCKET="uzone360live"
    var S3_REGION="us-east-1"
    //const S3_BUCKET = S3_BUCKET//process.env.REACT_APP_AWS_BUCKET;
    // S3 Region
    const REGION = S3_REGION //process.env.REACT_APP_AWS_REGION;
    // S3 Credentials
    AWS.config.update({
      accessKeyId: S3_KEY,//process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: S3_SECRET//process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    // let base64data = Buffer.from(selectFile, 'binary')
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      //Key: "userProfileImages/" + selectFile.name  ,
      Key: fileKey + selectFile.name  ,
      Body: selectFile,
      ContentType: fileType,
    };
    

    try {
      const stored = await s3.upload(params).promise()
      //console.log("upload Data",stored["Location"]);
      return stored["Location"];
    } catch (err) {
      //console.log(err)
    }

  };

export { handleImageUpload };