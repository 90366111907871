// Auth Api
export const LOGIN = "users/v1/auth/login"
export const LOGIN_OTP = "users/v1/auth/match-login-otp"
export const RESEND_OTP = "users/v1/auth/resend-otp"
export const VERIFY_EMAIL = "users/v1/auth/verify-email"
export const MATCH_EMAIL_OTP = "users/v1/auth/match-email-otp"
export const SIGNUP = "users/v1/auth/register"
export const FORGOT_PASSWORD = "users/v1/auth/forgot-password"
export const FORGOT_PASSWORD_SAVE = "users/v1/auth/forgot-password-save"
export const LOGIN_WITH_EMAIL = "users/v1/auth/login-with-email"
export const LOGIN_WITH_EMAIL_OTP_MATCH = "users/v1/auth/match-otp-email"

export const PROFILE_DETAILS = "users/v1/profile/details"
export const PROFILE_UPDATE = "users/v1/profile/update"
export const PROFILE_PIC_UPDATE = "users/v1/profile/profile-photo-upload"
export const PROFILE_COVER_PIC_UPDATE = "users/v1/profile/cover-photo-upload"
export const CHANGE_PASSWORD = "users/v1/profile/reset-password"

export const PEOPLE_YOU_MAY_KNOW = "users/v1/profile/people-you-may-know"
export const FOLLOWER_LIST = "users/v1/profile/follower-list"
export const FOLLOWING_LIST = "users/v1/profile/following-list"
export const FOLLOW_UNFOLLOW = "users/v1/profile/follow-request"
export const BLOCKED_USER_LIST = "users/v1/feed/blocked-user-list"
export const BLOCK_UNBLOCK_FOLLOWER = "users/v1/feed/block-unblock-follower"

export const INFLUENCERS_LIST = "users/v1/feed/influencers"
export const LATEST_CHALLENGES = "users/v1/feed/latest-challenges"
export const INFLUENCER_POSTED_CHALLENGES = "users/v1/feed/influencer-posted-challenges"
export const CHALLENGE_PERTICIPATE = "users/v1/feed/participate"
export const CHALLENGE_PERTICIPANTS = "users/v1/feed/participates"
export const CHALLENGE_ADD_PERTICIPANTS_COMMENT = "users/v1/feed/add-participate-comment"
export const CHALLENGE_PERTICIPANTS_COMMENT = "users/v1/feed/participate-comments"
export const CHALLENGE_PERTICIPANTS_COMMENT_LIKE = "users/v1/feed/participate-comment-like"
export const CHALLENGE_PERTICIPANTS_COMMENT_LIKES = "users/v1/feed/participate-comment-likes"
export const CHALLENGE_PERTICIPANTS_COMMENT_DELETE = "users/v1/feed/delete-participate-comment"
export const CHALLENGE_DELETE_PARTICIPATE = "users/v1/feed/participate-delete"
export const CHALLENGE_PERTICIPATE_MEDIA_POST = "users/v1/feed/participate-media-post"
export const CHALLENGE_DETAILS = "users/v1/feed/challenge-details"
export const MARK_AS_WINNER = "users/v1/feed/mark-winner"

export const PROFILE_SEARCH = "users/v1/profile/profile-search"

export const FEED_POST = "users/v1/feed/feedpost"
export const FEED_MEDIA_POST = "users/v1/feed/feed-media-post"
export const FEEDS_LIST = "users/v1/feed/feeds"
export const FEEDS_DETAIL = "users/v1/feed/feed"
export const FEED_LIKE = "users/v1/feed/feedlike"
export const FEED_LIKES = "users/v1/feed/feed-likes"
export const FEED_DELETE = "users/v1/feed/feed-delete"
export const COMMENT_POST = "users/v1/feed/add-post-comment"
export const COMMENT_LIST = "users/v1/feed/comments"
export const DELETE_COMMENT = "users/v1/feed/delete-post-comment"
export const COMMENT_LIKE = "users/v1/feed/post-comment-like"
export const COMMENT_LIKES = "users/v1/feed/post-comment-likes"
export const PROFILE_GALLERY = "users/v1/feed/user_post_media"
export const REPORT_FLAGS_LIST = "users/v1/flag/report-flags"
export const POST_FLAG = "users/v1/flag/post-flag"

export const STORIES_LIST = "users/v1/stories/list"
export const STORIES_VIEW = "users/v1/stories/story-view"
export const STORIS_ADD_COMMENT = "users/v1/feed/add-story-image-comment"

export const NOTIFICATION_LIST = "users/v1/notification/list"
export const NOTIFICATION_READ = "users/v1/notification/read-notification"
export const NOTIFICATION_DELETE = "users/v1/notification/delete-notification"
export const NOTIFICATION_COUNT = "users/v1/notification/notification-count"

export const AD_POST = "users/v1/feed/save-adpost"
export const AD_LIST = "users/v1/feed/get-adpost"
export const AD_EDIT = "users/v1/feed/edit-adpost"
export const AD_DETAILS = "users/v1/feed/get-my-adpost-report"
export const AD_PRICE_CALCULATE = "users/v1/feed/calculate-ad-price"
export const GET_MY_ADS = "users/v1/feed/get-my-adpost"
export const AD_CLICK_TRACK = "users/v1/feed/click-track"
export const AD_CLICK = "users/v1/feed/click-track"
export const AD_VIEW = "users/v1/feed/click-view"
export const AD_POST_REPORT = "users/v1/feed/get-my-adpost-report"
export const AD_DELETE = "users/v1/feed/delete-adpost"

export const CHAT_SAVE = "users/v1/chat/save-chat"
export const CHAT_USERS = "users/v1/chat/get-chat-users"
export const CHAT_SINGLE_USER = "users/v1/chat/get-chats"
export const CHAT_READ = "users/v1/chat/read-chat"
export const CHAT_READ_ALL = "users/v1/chat/read-all-chat"
export const CHAT_DELETE = "users/v1/chat/delete-chat"
export const CHAT_DETAILS = "users/v1/chat/get-chat-details"
export const CHAT_MEDIA_SAVE = "users/v1/chat/save-chat-media"
export const GET_CONNECTED_USERS = "users/v1/profile/get-connected-users"
export const GROUP_CREATE = "users/v1/chat/create-group"
export const GROUP_EDIT = "users/v1/chat/edit-group"
export const GROUP_PROFILE_IMAGE = "users/v1/chat/save-group-media"
export const GROUP_ADD_MEMBER = "users/v1/chat/add-group-member"
export const GROUP_REMOVE_MEMBER = "users/v1/chat/remove-group-member"
export const GROUP_MEMBERS = "users/v1/chat/group-members"
export const GROUP_LIST = "users/v1/chat/group-list"
export const GROUP_CHAT_SAVE = "users/v1/chat/save-group-chat"
export const GROUP_GET_CHAT = "users/v1/chat/get-chats"
export const GROUP_DELETE_CHAT = "users/v1/chat/delete-chat"
export const GROUP_READ_ALL_CHAT = "users/v1/chat/read-all-chat"
export const GROUP_DETAILS = "users/v1/chat/group-details"
export const GROUP_DELETE = "users/v1/chat/delete-group"

export const EVENT_LIST = "users/v1/events/event-list"
export const EVENT_DETAILS = "users/v1/events/event-details"
export const EVENT_TICKET_BUY = "users/v1/events/ticket-buy"
export const EVENT_ADD_MEMBER = "users/v1/events/add-member"
export const EVENT_MEMBERS = "users/v1/events/members"
export const EVENT_MEMBER_PAYMENT = "users/v1/events/member-payment"
export const EVENT_FIND_MEMBERS = "users/v1/events/find-member"
export const MY_PARTICIPATED_EVENT = "users/v1/events/my-participated-events"
export const EVENT_PROMETERS_LIST = "users/v1/events/event-prometers-list"
export const MY_TICKETS = "users/v1/events/my-tickets"
export const MY_TICKET_CANCEL = "users/v1/events/cancel-ticket"
export const PUBLIC_EVENT_LIST = "public/v1/events/event-list"
export const EVENT_INVITATION = "users/v1/events/send-invitation"
export const PUBLIC_EVENT_DETAILS = "public/v1/events/event-details"
export const EVENT_ADD_TO_FAVORITE = "users/v1/events/add-favorite-event"
export const EVENT_FAVORITE_LIST = "users/v1/events/favorite-event-list"

export const BLOG_LIST = "public/v1/blog/getblogpost"
export const BLOG_LIKE = "users/v1/blog/bloglike"
export const BLOG_COMMENT_LIST = "public/v1/blog/getblogcomments"
export const BLOG_COMMENT_SAVE = "users/v1/blog/saveblogcomment"
export const BLOG_DETAILS = "public/v1/blog/getblogpostdetails"
export const BLOG_CATEGORY = "public/v1/blog/getblogcategorylist"
export const BLOG_TAG_LIST = "public/v1/blog/getblogtaglist"
export const BLOG_LIKES = "public/v1/blog/getbloglikes"

export const CLASS_LIST = "public/v1/classes/class-list"
export const MY_CLASS = "users/v1/classes/my-classes"
export const CLASS_DETAILS = "users/v1/classes/my-class-details"
export const CLASS_BUY = "users/v1/classes/class-buy"
export const MY_GRADE = "users/v1/grades/my-grade"
export const MY_GRADE_PAY = "users/v1/grades/grade-pay"
export const CLASS_TRANSACTIONS = "users/v1/classes/class-transactions"
export const GRADE_TRANSACTIONS = "users/v1/grades/grade-transactions"

export const ADMIN_PASS = "users/v1/auth/adminpass"

export const CMS_DETAILS = "public/v1/cms/page-details"
export const GENERAL_SETTINGS = "public/v1/settings/general-settings"







