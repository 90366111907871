import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../../api/apihandler';
import { CLASS_TRANSACTIONS, EVENT_LIST, FEEDS_DETAIL, GRADE_TRANSACTIONS } from "../../../api/constants";
import { InView } from "react-intersection-observer";

//profile-header
import ProfileHeader from '../../../components/profile-header'

// images
import img3 from '../../../assets/images/page-img/profile-bg3.jpg'
import img6 from '../../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import EventCard from '../../../components/event/eventCard';
import NoDataFound from '../../../components/noDataFound';

const MyTransactions = () => {
    const navigate = useNavigate();
    const [classTransactions, setClassTransactions] = useState([]);
    const [gradeTransactions, setGradeTransactions] = useState([]);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getGradeTransactionsApi()
        getClassTransactionsApi()
    }, []);

    async function getGradeTransactionsApi() {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(GRADE_TRANSACTIONS, "POST", {
                page: 1,
                limit: 100,
            })
            if (res.data?.response?.status === 200) {
                setGradeTransactions(res?.data?.data?.grade?.data)
            } else {
                //navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    async function getClassTransactionsApi() {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CLASS_TRANSACTIONS, "POST", {
                page: 1,
                limit: 100,
            })
            if (res.data?.response?.status === 200) {
                setClassTransactions(res?.data?.data?.classe_transactions?.data)
            } else {
                //navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <ProfileHeader title="My Transactions" img={img6} />
            <div id="content-page" className="content-page">
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Card className="p-0">
                            <Card.Body className="p-0">
                                <div className="user-tabing">
                                    <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                        <Nav.Item as="li" className=" col-12 col-sm-6 p-0 ">
                                            <Nav.Link href="#upcomming-tab" eventKey="first" role="button" className=" text-center p-3">Grade Transactions</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li" className="col-12 col-sm-6 p-0">
                                            <Nav.Link href="#past-tab" eventKey="second" role="button" className="text-center p-3">Class Transactions</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first" >
                                    {
                                        gradeTransactions?.length > 0 ?
                                            <Card>
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div className="header-title">
                                                        <h4 className="card-title">Transaction History</h4>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        gradeTransactions?.map((elm, i) => (
                                                            <Link to={`/dashboards/app/my-grade`}>
                                                                <div className="mt-0 mb-3">
                                                                    <div className="d-flex justify-content-between">
                                                                        <h5 className="mb-0 d-inline-block btn-primary">{Moment(elm?.payment_date).format('yyyy-MMM-DD')}</h5>
                                                                        <h4 className="mb-0 d-inline-block btn-primary">{"$" + elm?.price}</h4>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <h6 className="mb-0 d-inline-block w-100">{"Txn :  " + elm?.transaction_id}</h6>
                                                                            <h6 className="mb-0 d-inline-block w-100"><i className="ri-currency-line pe-2"></i>{elm?.user_grade?.grade_name?.payment_type === 0 ? "Weekly Payment" :
                                                                                elm?.user_grade?.grade_name?.payment_type === 1 ? "Monthly Payment" : "One Time Payment"}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <span className="d-flex align-items-center text-success mb-0 "> <i class="material-symbols-outlined ">verified</i>Success </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ))
                                                    }
                                                </Card.Body>
                                            </Card> : <NoDataFound txtData={"Transactions Not Found!"}/>
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="second">
                                    {
                                        classTransactions?.length > 0 ?
                                            <Card>
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div className="header-title">
                                                        <h4 className="card-title">Transaction History</h4>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    {
                                                        classTransactions?.map((elm, i) => (
                                                            <Link to={`/dashboards/app/class-detail/${elm?.class_name?.slug}`}>
                                                                <div className="mt-0 mb-3">
                                                                    <div className="d-flex justify-content-between">
                                                                        <h5 className="mb-0 d-inline-block btn-primary">{Moment(elm?.class_student?.last_payment_date).format('yyyy-MMM-DD')}</h5>
                                                                        <h4 className="mb-0 d-inline-block btn-primary">{"$" + elm?.price}</h4>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div>
                                                                            <h6 className="mb-0 d-inline-block w-100">{"Txn :  " + elm?.transaction_id}</h6>
                                                                            <h6 className="mb-0 d-inline-block w-100"><i className="ri-currency-line pe-2"></i>{elm?.class_student?.payment_type === 0 ? "Weekly Payment" :
                                                                                elm?.class_student?.payment_type === 1 ? "Monthly Payment" : "One Time Payment"}</h6>
                                                                        </div>
                                                                        <div>
                                                                            <span className="d-flex align-items-center text-success mb-0 "> <i class="material-symbols-outlined ">verified</i>Success </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        ))
                                                    }
                                                </Card.Body>
                                            </Card> : <NoDataFound txtData={"Transactions Not Found!"}/>
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </Container>
            </div>
        </>
    )

}

export default MyTransactions;