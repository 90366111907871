import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal, Tab, Nav } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link, useNavigate, Route } from 'react-router-dom'
import Moment from 'moment';
import { apiHandler } from '../../../../api/apihandler';
import { CLASS_LIST } from "../../../../api/constants";
import { InView } from "react-intersection-observer";

//profile-header
import ProfileHeader from '../../../../components/profile-header'

// images
import img3 from '../../../../assets/images/page-img/profile-bg3.jpg'
import img6 from '../../../../assets/images/page-img/profile-bg6.jpg'
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import EventCard from '../../../../components/event/eventCard';
import NoDataFound from '../../../../components/noDataFound';
import ClassCard from '../../../../components/classes/classCard';

const Classes = () => {
    const navigate = useNavigate();
    const [classList, setClassList] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

    useEffect(() => {
        getClassList(1)
    }, []);

    function refreshApi() {
        setPage(1)
        getClassList(1)
    }

    const loadMore = (inView) => {
        if (inView) {
            if (page < maxPage) {
                getClassList(page + 1)
                setPage(page + 1)
            }
        }
    };

    async function getClassList(pageVal) {
        setShowMoreDataLoader(true)
        try {
            const res = await apiHandler(CLASS_LIST, "POST", {
                page: pageVal,
                limit: 10,
            })
            if (res.data?.response?.status === 200) {
                setMaxPage(res?.data?.data?.classes?.meta?.last_page)
                if (res?.data?.data?.classes?.meta?.total > 0) {
                    if (pageVal === 1) {
                        setClassList(res?.data?.data?.classes?.data)
                    } else {
                        setClassList(classList.concat(res?.data?.data?.classes?.data))
                    }
                }
            } else {
                //navigate('/auth/sign-in')
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowMoreDataLoader(false)
        }
    }

    return (
        <>
            <ProfileHeader title="All Classes" img={img6} />
            <div id="content-page" className="content-page">
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        {/* <Card className="p-0">
                     <Card.Body className="p-0">
                        <div className="user-tabing">
                           <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                              <Nav.Item as="li" className=" col-12 col-sm-6 p-0 " onClick={()=> setIsPast(false)}>
                                 <Nav.Link href="#upcomming-tab" eventKey="first" role="button" className=" text-center p-3">Upcoming Event</Nav.Link>
                              </Nav.Item>
                              <Nav.Item as="li" className="col-12 col-sm-6 p-0" onClick={()=> setIsPast(true)}>
                                 <Nav.Link href="#past-tab" eventKey="second" role="button" className="text-center p-3">Past Event</Nav.Link>
                              </Nav.Item>
                           </Nav>
                        </div>
                     </Card.Body>
                  </Card> */}
                        <Col sm={12}>
                            <Card.Body className=" p-0">
                                <Row>
                                    <Col lg={12}>
                                        <div className="d-grid gap-3 d-grid-template-1fr-19 mb-5">
                                            {
                                                classList?.length > 0 ? classList.map((elm, i) => (
                                                    <ClassCard elm={elm} getClassList={refreshApi} key={elm?.id} />
                                                )) :
                                                    <NoDataFound />}
                                        </div>
                                        {
                                            showMoreDataLoader ?
                                                <div className="col-sm-12 text-center">
                                                    <img src={loader} alt="loader" style={{ height: "100px" }} />
                                                </div> : null
                                        }
                                        <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Tab.Container>
                    {/* <div className="d-grid gap-3 d-grid-template-1fr-19 mb-5">
                  {
                     eventList?.length > 0 ? eventList.map((elm, i) => (
                        <EventCard elm={elm} getEventList={refreshApi} key={elm?.id} />
                     )) : null} */}

                    {/* <div>
                        <Card className=" rounded  mb-0">
                           <div className="event-images">
                              <Link to="#">
                                 <img src={img51} className="img-fluid" alt="Responsive"/>
                              </Link>
                           </div>
                           <Card.Body>
                              <div className="d-flex">
                                 <div className="date-of-event">
                                    <span>Jan</span>
                                    <h5>01</h5>
                                 </div>
                                 <div className="events-detail ms-3">
                                    <h5><Link to="/dashboards/app/event-detail">New Year Celebration</Link></h5>
                                    <p>Lorem Ipsum is simply dummy text</p>
                                    <div className="event-member">
                                       <div className="iq-media-group">
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user05} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user06} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user07} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user08} alt=""/>
                                          </Link>
                                          <Link to="#" className="iq-media">
                                             <img className="img-fluid avatar-40 rounded-circle" src={user09} alt=""/>
                                          </Link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Card.Body>
                        </Card>
                     </div> */}
                    {/* </div>
               {
                  showMoreDataLoader ?
                     <div className="col-sm-12 text-center">
                        <img src={loader} alt="loader" style={{ height: "100px" }} />
                     </div> : null
               }
               <InView as="div" onChange={(inView, entry) => loadMore(inView)} /> */}
                </Container>
            </div>
        </>
    )

}

export default Classes;