import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { apiHandler } from '../../../../api/apihandler';
import { GENERAL_SETTINGS } from "../../../../api/constants";
// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

//image
import logo from '../../../../assets/images/logo.png'
import apple from '../../../../assets/images/getappimg/1.svg'
import android from '../../../../assets/images/getappimg/2.svg'
import Swal from 'sweetalert2';

const Footer = (props) => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";

    const navigate = useNavigate();
    const [generalSettings, setGeneralSettings] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('dark');
    if (mode !== null) {
        document.body.classList = ''
        switch (mode) {
            case "true":
                document.body.classList = 'dark'
                break;
            case "false":
                document.body.classList = ''
                break;
            default:
                document.body.classList = ''
                break;
        }
    }

    useEffect(() => {
        getGeneralSettings()
    }, []);


    async function getGeneralSettings() {
        try {
            const res = await apiHandler(GENERAL_SETTINGS, "POST", {
            })
            if (res.data?.response?.status === 200) {
                setGeneralSettings(res?.data?.data?.generalSettings)
            }
        } catch (error) {
            console.error(error);
        }
    }
    function showLoginAlert() {
        Swal.fire({
            title: 'Warning!',
            text: 'You are not loged in, are you sure you want to login?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Go to Login',
            confirmButtonColor: "#5c518b"
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/auth/sign-in')
            }
        })
    }

    function showWarningAlert() {
        Swal.fire({
            title: 'Warning!',
            text: 'Sorry you are not eligible for this features!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        })
    }


    return (
        <>
            <footer className="iq-footer bg-white">
                <Container fluid className='mt-2'>
                    <Row className="mb-1">
                        <div className="d-flex justify-content-between d-lg-none w-100">
                            <div >
                                <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">
                                    <Link
                                        to={profileData?.user_type === 4 ? "/dashboards/app/events" :
                                            profileData?.user_type === 2 ? "/dashboards/app/announcement" : "/"}
                                        className="d-flex align-items-center gap-2 iq-header-logo">
                                        <Image src={logo} className="img-fluid" style={{ width: 40 }} />
                                        <h3 className="logo-title d-none d-sm-block" data-setting="app_name">Uzone360</h3>
                                    </Link>
                                </div>
                                <div>
                                    <h4 className="logo-title d-none d-sm-block mt-4" data-setting="app_name">Call Us</h4>
                                    <h5 className="logo-title d-none d-sm-block" data-setting="app_name"><Link>{generalSettings?.phone_no}</Link></h5>
                                    <p className="pt-3 pb-1">{generalSettings?.address} <br />{generalSettings?.email}</p>
                                </div>
                                {/* <div>
                                    <h4 className="logo-title d-none d-sm-block mt-0 uppercase" data-setting="app_name">GET ACCESS FROM ANYWHERE</h4>
                                    <div className="mt-2">
                                        <img src={apple} alt="loader" style={{ height: "45px", cursor: "pointer" }} />
                                        <img src={android} alt="loader" style={{ height: "45px", marginLeft: "13px", cursor: "pointer" }} />
                                    </div>
                                </div> */}
                            </div>
                            <div >
                                <div className="d-flex d-inline-block align-items-center gap-4  pb-0 pb-lg-2">
                                    <h4>ABOUT</h4>
                                </div>
                                <div>
                                    <Link to="dashboard/extrapages/about-us">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">About Us</h5>
                                    </Link>
                                    <Link to="dashboard/Email/email-compose">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Contact Us</h5>
                                    </Link>
                                    <Link to="/dashboard/extrapages/terms-of-service">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Terms of Use</h5>
                                    </Link>
                                    <Link to="/dashboard/extrapages/privacy-policy">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Privacy Policy</h5>
                                    </Link>
                                </div>
                            </div>
                            <div >
                                <div className="d-flex d-inline-block align-items-center gap-4  pb-0 pb-lg-2">
                                    <h4>OUR FEATURES</h4>
                                </div>
                                <div>
                                    <Link to="#" onClick={() => {
                                        if (localStorage.getItem("uzoneAuthToken")) {
                                            navigate('/dashboards/app/events')
                                        } else {
                                            navigate('/dashboard/app/publicEvents')
                                        }
                                    }}>
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Our Event</h5>
                                    </Link>
                                    <Link to="#"
                                        onClick={() => {
                                            if (localStorage.getItem("uzoneAuthToken")) {
                                                if (profileData?.user_type === 4) {
                                                    showWarningAlert()
                                                } else {
                                                    navigate('/dashboards/app/groups')
                                                }
                                            } else {
                                                showLoginAlert()
                                            }
                                        }}>
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Groups</h5>
                                    </Link>
                                    <Link to="#" onClick={() => {
                                        if (localStorage.getItem("uzoneAuthToken")) {
                                            navigate('/dashboard/blog/blog-list')
                                        } else {
                                            navigate('/dashboard/app/public-blog')
                                        }
                                    }}>
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Our Blog</h5>
                                    </Link>
                                </div>
                            </div>
                            {/* <div >
                                <div className="d-flex d-inline-block align-items-center gap-4  pb-0 pb-lg-2">
                                    <h4>SUPPORT</h4>
                                </div>
                                <div>
                                    <Link to="dashboard/Email/email-compose">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Contact Us</h5>
                                    </Link>
                                    <Link to="/dashboard/extrapages/terms-of-service">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Terms of Use</h5>
                                    </Link>
                                    <Link to="/dashboard/extrapages/privacy-policy">
                                        <h5 className="logo-title d-none d-sm-block mt-2 mb-2 cursor-pointer" data-setting="app_name">Privacy Policy</h5>
                                    </Link>
                                </div>
                            </div> */}
                            <div className=" align-items-center">
                                <h4 className="logo-title d-none d-sm-block mt-0 uppercase" data-setting="app_name">GET ACCESS FROM ANYWHERE</h4>
                                <div className="mt-4">
                                    <img src={apple} alt="loader" style={{ height: "40px", cursor: "pointer" }} />
                                    <img src={android} alt="loader" style={{ height: "40px", marginLeft: "13px", cursor: "pointer" }} />
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div>
                        <hr />
                        <Row className="mt-3 mb-0">
                            <Col lg="6" >
                                <span>© 2024 Uzone360. All Right Reserved.</span>
                            </Col>
                            <Col lg="6" className="d-flex justify-content-end">
                                {/* <ul className="list-inline mb-0">
                                <li className="list-inline-item"><Link to="/dashboard/extrapages/privacy-policy">Privacy Policy</Link></li>{' '}
                                <li className="list-inline-item"><Link to="/dashboard/extrapages/terms-of-service">Terms of Use</Link></li>
                            </ul> */}
                                <ul className="list-inline mb-0">
                                    {/* <li className="list-inline-item"><h6>Follow Us On </h6></li> */}
                                    <li className="list-inline-item ms-4" style={{ font: "icon" }}><Link to={generalSettings?.fb_link} target='_blank noopener,noreferrer'><i className="ri-facebook-fill" /></Link></li>
                                    <li className="list-inline-item ms-4" style={{ font: "icon" }}><Link to={generalSettings?.twit_link} target='_blank noopener,noreferrer'><i className="ri-twitter-fill " /></Link></li>
                                    <li className="list-inline-item ms-4" style={{ font: "icon" }}><Link to={generalSettings?.insta_link} target='_blank noopener,noreferrer'><i className="ri-instagram-line" /></Link></li>
                                    <li className="list-inline-item ms-4" style={{ font: "icon" }}><Link to={generalSettings?.linked_link} target='_blank noopener,noreferrer'><i className="ri-linkedin-fill" /></Link></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default (Footer)