import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, OverlayTrigger, Tooltip, Button, Modal, Form, ProgressBar } from 'react-bootstrap'
import Card from '../../../../components/Card'
import CustomToggle from '../../../../components/dropdowns'
import { InView } from "react-intersection-observer";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { apiHandler } from '../../../../api/apihandler';
import { AD_DELETE, AD_DETAILS, GET_MY_ADS } from "../../../../api/constants";
import Swal from 'sweetalert2'
import Moment from 'moment';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// Datepicker
// import Datepicker from '../../../../components/datepicker'
import { DateRange, DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // default styles
import 'react-date-range/dist/theme/default.css'; // default theme
// apex-chart
import Chart from "react-apexcharts"

// Redux Selector / Action
import { useSelector } from 'react-redux';

//image
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import cross from '../../../../assets/images/icon/cross.png'
import playButton from '../../../../assets/images/icon/play_button.png'
import LoaderModal from '../../../../components/loaderModal'
import AdCard from '../../../../components/ads/adCard'
import EventRightSide from '../../../../components/event/eventRightSide'
import AdEditCard from '../../../../components/ads/adEditCard';


const AdsDetails = () => {
    const navigate = useNavigate();
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const { slug } = useParams();
    const [adDetails, setAdDetails] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showEditPostModal, setShowEditPostModal] = useState(false);
    const handleClose = () => setShowEditPostModal(false);
    const [adminChart, setAdminChart] = useState({
        options: {
            colors: ["#5c518b"],
            chart: {
                toolbar: {
                    show: false
                },
            },
            forecastDataPoints: {
                count: 2,
            },
            stroke: {
                width: 3,
            },
            grid: {
                show: true,
                strokeDashArray: 7,
            },
            markers: {
                size: 6,
                colors: '#FFFFFF',
                strokeColors: ["#5c518b"],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 0,
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                }
            },
        },
        series: [{
            name: 'Total View',
            data: [42, 30, 25, 40, 57, 71, 86, 71, 108]
        },
        {
            name: 'Total Click',
            data: [4, 3, 2, 4, 5, 7, 8, 7, 10]
        }],
    })
    const [state, setState] = useState(
        {
            selection: {
                startDate: new Date(),
                endDate: addDays(new Date(), 0),
                // startDate: "2024-08-02",
                // endDate: addDays("2024-08-31", 28),
                key: 'selection'
            }
        }
    );

    const handleSelect = ranges => {
        setState({
            selection: ranges.selection
        });
        getFilterAdDetails(Moment(ranges.selection?.startDate).format("yyyy-MM-DD"), Moment(ranges.selection?.endDate).format("yyyy-MM-DD"))
    };

    useEffect(() => {
        if (localStorage.getItem("uzoneAuthToken")) {
            getAdDetails()
        } else {
            navigate('/auth/sign-in')
        }
    }, []);

    useEffect(() => {
        getFilterAdDetails(Moment(adDetails?.userPost?.user_post_ad?.ad_start_date).format("yyyy-MM-DD"), Moment(adDetails?.userPost?.user_post_ad?.ad_end_date).format("yyyy-MM-DD"))
    }, [adDetails?.userPost?.user_post_ad?.ad_start_date]);

    // useEffect(() => {
    //     if (localStorage.getItem("uzoneAuthToken")) {
    //         if (dataFilterCat === "Week") {
    //             const current = Moment()
    //             var weekStart = "";
    //             var weekEnd = "";
    //             var days = [];
    //             let n = 7
    //             while (n > 0) {
    //                 if (n === 7) {
    //                     weekEnd = current.format("yyyy-M-D")
    //                     // console.log("weekEnd", weekEnd)
    //                 } else if (n === 1) {
    //                     weekStart = current.format("yyyy-M-D")
    //                     // console.log("weekStart", weekStart)
    //                 }
    //                 days.push(current.format("dddd"))
    //                 current.subtract(1, "day")
    //                 n--;
    //             }
    //             getFilterAdDetails(weekStart, weekEnd, days.reverse())
    //             setDataFilterCatArray(days.reverse())
    //         } else if (dataFilterCat === "Month") {
    //             var daysInMonth = Moment().daysInMonth();
    //             var arrDays = [];
    //             while (daysInMonth) {
    //                 var current = Moment().date(daysInMonth).format('DD');
    //                 arrDays.push(current);
    //                 daysInMonth--;
    //             }
    //             setDataFilterCatArray(arrDays.reverse())
    //         } else {
    //             setDataFilterCatArray(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'])
    //         }
    //     } else {
    //         navigate('/auth/sign-in')
    //     }
    // }, [dataFilterCat]);

    async function getFilterAdDetails(fromDate, toDate) {
        setShowLoader(true)
        try {
            const res = await apiHandler(AD_DETAILS, "POST", {
                ad_id: slug,
                from_date: fromDate,
                to_date: toDate
            })
            if (res.data?.response?.status === 200) {
                var dateArray = [];
                var viewsArray = [];
                var clicksArray = [];
                for (var i = 0; i < res?.data?.data?.reports?.data?.length; i++) {
                    dateArray.push(Moment(res?.data?.data?.reports?.data[i]?.created_at).format('DD MMM'))
                    viewsArray.push(res?.data?.data?.reports?.data[i]?.total_view)
                    clicksArray.push(res?.data?.data?.reports?.data[i]?.total_click)
                }
                setAdminChart({
                    options: {
                        colors: ["#5c518b"],
                        chart: {
                            toolbar: {
                                show: false
                            },
                        },
                        forecastDataPoints: {
                            count: 2,
                        },
                        stroke: {
                            width: 3,
                        },
                        grid: {
                            show: true,
                            strokeDashArray: 7,
                        },
                        markers: {
                            size: 6,
                            colors: '#FFFFFF',
                            strokeColors: ["#5c518b"],
                            strokeWidth: 2,
                            strokeOpacity: 0.9,
                            strokeDashArray: 0,
                            fillOpacity: 0,
                            shape: "circle",
                            radius: 2,
                            offsetX: 0,
                            offsetY: 0,
                        },
                        xaxis: {
                            categories: dateArray?.reverse(),
                            axisBorder: {
                                show: false,
                            },
                            axisTicks: {
                                show: false,
                            },
                            tooltip: {
                                enabled: false,
                            }
                        },
                        line: {
                            marker: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    },
                    series: [{
                        name: 'Total View',
                        data: viewsArray?.reverse()
                    },
                    {
                        name: 'Total Click',
                        data: clicksArray?.reverse()
                    }],
                })
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getAdDetails() {
        setShowLoader(true)
        try {
            const res = await apiHandler(AD_DETAILS, "POST", {
                ad_id: slug,
                from_date: "",
                to_date: ""
            })
            if (res.data?.response?.status === 200) {
                setState({
                    selection: {
                        startDate: res?.data?.data?.userPost?.user_post_ad?.ad_start_date,
                        endDate: addDays(res?.data?.data?.userPost?.user_post_ad?.ad_end_date, 0),
                        key: 'selection'
                    }
                })
                setAdDetails(res?.data?.data)
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setShowLoader(false)
        }
    }

    async function getAdDelete() {
        if (localStorage.getItem("uzoneAuthToken")) {
            try {
                const res = await apiHandler(AD_DELETE, "DELETE", {
                    post_id: slug
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: 'Your Advertise deleted successfully',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getAdDetails()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            } finally {
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    // const adminChart = {
    //     options: {
    //         colors: ["#5c518b"],
    //         chart: {
    //             toolbar: {
    //                 show: false
    //             },
    //         },
    //         forecastDataPoints: {
    //             count: 2,
    //         },
    //         stroke: {
    //             width: 3,
    //         },
    //         grid: {
    //             show: true,
    //             strokeDashArray: 7,
    //         },
    //         markers: {
    //             size: 6,
    //             colors: '#FFFFFF',
    //             strokeColors: ["#5c518b"],
    //             strokeWidth: 2,
    //             strokeOpacity: 0.9,
    //             strokeDashArray: 0,
    //             fillOpacity: 0,
    //             shape: "circle",
    //             radius: 2,
    //             offsetX: 0,
    //             offsetY: 0,
    //         },
    //         xaxis: {
    //             categories: dataFilterCatArray,
    //             axisBorder: {
    //                 show: false,
    //             },
    //             axisTicks: {
    //                 show: false,
    //             },
    //             tooltip: {
    //                 enabled: false,
    //             }
    //         },
    //     },
    //     series: [{
    //         name: 'Total View',
    //         data: totalViewArray
    //     },
    //     {
    //         name: 'Total Click',
    //         data: totalClickArray
    //     }],
    // }


    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <div id='content-page' className='content-page'>
                <Container>
                    <Row as="ul" className="list-unstyled mb-0">
                        <Col md="6" lg="3" as="li">
                            <Card>
                                <Card.Body>
                                    <div className="points">
                                        <span>Total Views</span>
                                        <div className="d-flex align-items-center">
                                            <h3>{adDetails?.userPost?.overall_view ? adDetails?.userPost?.overall_view : "0"}</h3>
                                            {/* <small className="text-success ms-3">+ 57</small> */}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="6" lg="3" as="li">
                            <Card>
                                <Card.Body>
                                    <div className="points">
                                        <span>Total Clicks</span>
                                        <div className="d-flex align-items-center">
                                            <h3>{adDetails?.userPost?.overall_click ? adDetails?.userPost?.overall_click : "0"}</h3>
                                            {/* <small className="text-danger ms-3">- 12,562</small> */}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="6" lg="3" as="li">
                            <Card>
                                <Card.Body>
                                    <div className="points">
                                        <span>Total Amount</span>
                                        <div className="d-flex align-items-center">
                                            <h3>${adDetails?.userPost?.user_post_ad?.total_costing ? adDetails?.userPost?.user_post_ad?.total_costing : "0"}</h3>
                                            {/* <small className="text-success ms-3">+ 1,056</small> */}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="6" lg="3" as="li">
                            <Card>
                                <Card.Body>
                                    <div className="points">
                                        <span>Duration</span>
                                        <div className="d-flex align-items-center">
                                            <h3>{Moment(adDetails?.userPost?.user_post_ad?.ad_start_date).format('DD MMM')} {" - "}
                                                {Moment(adDetails?.userPost?.user_post_ad?.ad_end_date).format('DD MMM')}
                                            </h3>
                                            {/* <small className="text-success ms-3">+ 28,476</small> */}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card className="card-block card-stretch card-height">
                                <Card.Header>
                                    <h4 className="card-title">Ad Clicks & Views</h4>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
                                            <div className="text-success d-flex align-items-center">
                                                <h6 className='text-primary'>
                                                    {Moment(state.selection?.startDate).format('DD MMM YYYY')} {" - "}
                                                    {Moment(state.selection?.endDate).format('DD MMM YYYY')}
                                                </h6>
                                                <i className="ri-arrow-down-s-line text-primary ms-1"></i>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-end">
                                            <div className="input-group d-block">
                                                {/* <DateRange */}
                                                <DateRangePicker
                                                    ranges={[state.selection]}
                                                    onChange={handleSelect}
                                                    showMonthAndYearPickers={false}
                                                    showDateDisplay={false}
                                                />
                                            </div>
                                            {/* <li><Dropdown.Item href="#" onClick={() => setDataFilterCat("Week")}>Week</Dropdown.Item></li>
                                            <li><Dropdown.Item href="#" onClick={() => setDataFilterCat("Month")}>Month</Dropdown.Item></li>
                                            <li><Dropdown.Item href="#" onClick={() => setDataFilterCat("Year")}>Year</Dropdown.Item></li> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Card.Header>
                                <Card.Body>
                                    <Chart options={adminChart.options} series={adminChart.series} type="area" height="198" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title ">Ad Status</h4>
                                    </div>
                                    {/* <div className="card-header-toolbar d-flex align-items-center">
                                        <Link to="#" className="material-symbols-outlined">
                                            add
                                        </Link>
                                    </div> */}
                                </Card.Header>
                                <Card.Body>
                                    <ul className="m-0 p-0 job-classification list-inline" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <li className="d-flex align-items-center"><i className="material-symbols-outlined md-18 bg-primary">check_circle</i>
                                            {
                                                adDetails?.userPost?.user_post_ad?.position === 1 ? "Ad Position: Right" :
                                                    adDetails?.userPost?.user_post_ad?.position === 2 ? "Ad Position: Middle" : "Add Position: Left"
                                            }
                                        </li>
                                        <li className="d-flex align-items-center"><i className="material-symbols-outlined md-18 bg-info">check_circle</i>
                                            Validity: {Moment(adDetails?.userPost?.user_post_ad?.ad_start_date).format('DD MMM YY')} {" - "}
                                            {Moment(adDetails?.userPost?.user_post_ad?.ad_end_date).format('DD MMM YY')}
                                        </li>
                                        <li className="d-flex align-items-center"><i className="material-symbols-outlined md-18 bg-success">check_circle</i>
                                            {
                                                adDetails?.userPost?.user_post_ad?.status === 1 ? "Ad Status: Active" : "Ad Status: Deactive"
                                            }
                                        </li>
                                        {/*<li className="d-flex align-items-center"><i className="material-symbols-outlined md-18 bg-warning">check_circle</i>Team Project</li> */}
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title ">Order Details</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="table-responsive-sm">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Transaction No</th>
                                                    <th scope="col">Payment Mode</th>
                                                    <th scope="col">Due Date</th>
                                                    <th scope="col">Sub-total</th>
                                                    <th scope="col">Discount</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">{adDetails?.userPost?.user_post_ad?.transaction_id ? adDetails?.userPost?.user_post_ad?.transaction_id : "0"}</th>
                                                    <td>Card Payment</td>
                                                    <td>{Moment(adDetails?.userPost?.user_post_ad?.created_at).format('DD MMMM YYYY')}</td>
                                                    <td>${adDetails?.userPost?.user_post_ad?.total_costing ? adDetails?.userPost?.user_post_ad?.total_costing : "0"}</td>
                                                    <td>0%</td>
                                                    <td><b>${adDetails?.userPost?.user_post_ad?.total_costing ? adDetails?.userPost?.user_post_ad?.total_costing : "0"}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="post-item">
                                        <div className="user-post-data pb-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="me-3">
                                                    <img loading="lazy" className="rounded-circle  avatar-45"
                                                        src={adDetails?.userPost?.user?.user_setting?.photo ? adDetails?.userPost?.user?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="" />
                                                </div>
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block"><Link to={profileData?.user_type === 4 ? "#" : `/dashboard/app/friend-profile/${adDetails?.userPost?.user?.slug}`}>{adDetails?.userPost?.user?.first_name + " " + adDetails?.userPost?.user?.last_name}</Link></h5>
                                                            <p className="mb-0">{Moment(adDetails?.userPost?.user_post_ad?.ad_start_date).format('DD MMMM YYYY')} {" - "}{Moment(adDetails?.userPost?.user_post_ad?.ad_end_date).format('DD MMMM YYYY')}</p>
                                                        </div>
                                                        {
                                                            (Moment(new Date())) <= (Moment(adDetails?.userPost?.user_post_ad?.ad_end_date)) ?
                                                                <div className="card-post-toolbar">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="bg-transparent">
                                                                            <span className="material-symbols-outlined">
                                                                                more_horiz
                                                                            </span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className=" m-0 p-0">
                                                                            {
                                                                                Moment(adDetails?.userPost?.user_post_ad?.ad_start_date) > new Date ?
                                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                                        <div className="d-flex align-items-top"
                                                                                            onClick={() =>
                                                                                                Swal.fire({
                                                                                                    title: 'Warning!',
                                                                                                    text: 'Are you sure, want to delete this feed?',
                                                                                                    icon: 'warning',
                                                                                                    showCancelButton: true,
                                                                                                    showConfirmButton: true,
                                                                                                    cancelButtonText: 'Cancel',
                                                                                                    confirmButtonText: 'Confirm',
                                                                                                    confirmButtonColor: "#5c518b"
                                                                                                }).then((result) => {
                                                                                                    if (result.isConfirmed) {
                                                                                                        getAdDelete()
                                                                                                    }
                                                                                                })}>
                                                                                            <i className="ri-delete-bin-7-line h4"></i>
                                                                                            <div className="data ms-2">
                                                                                                <h6>Delete</h6>
                                                                                                <p className="mb-0">Remove this Post from your profile</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Dropdown.Item> : null
                                                                            }
                                                                            <Dropdown.Item className=" p-3" to="#">
                                                                                <div className="d-flex align-items-top" onClick={() => {
                                                                                    
                                                                                    setShowEditPostModal(true)
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill h4"></i>
                                                                                    <div className="data ms-2">
                                                                                        <h6>Edit Post</h6>
                                                                                        <p className="mb-0">Update your post and saved items</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div> : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            adDetails?.userPost?.user_post_ad?.title ?
                                                <div className="mt-1">
                                                    <p>{adDetails?.userPost?.user_post_ad?.title}</p>
                                                </div> : null
                                        }
                                        <div className="user-post">
                                            <Link to={`/dashboards/app/ads-details/${adDetails?.userPost?.id}`}>
                                                <ResponsiveMasonry
                                                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}>
                                                    <Masonry columnsCount={3} gutter="10px">
                                                        {adDetails?.userPost?.user_post_images.map((image, i) => (
                                                            image?.media_type === 1 ?
                                                                <div key={image?.id} onClick={() => {
                                                                    //imageOnSlide(i + 1)
                                                                }}>
                                                                    <div className="img-fluid" style={{ position: "relative" }} >
                                                                        <img loading="lazy"
                                                                            src={image?.thumb} alt="gallary" className="img-fluid" />
                                                                    </div>
                                                                    <Link style={{ position: "absolute", left: "47%", top: "47%", zIndex: 1 }}>
                                                                        <img style={{ height: 50, width: 50 }} src={playButton} />
                                                                    </Link>
                                                                </div> :
                                                                <img key={image?.id} loading="lazy" src={image?.image_path}
                                                                    alt="post" className="img-fluid w-100"
                                                                    onClick={() => {
                                                                        //imageOnSlide(i + 1)
                                                                    }} />
                                                        ))}
                                                    </Masonry>
                                                </ResponsiveMasonry>
                                            </Link>
                                        </div>
                                        <div className="d-flex justify-content-between flex-wrap mt-2">
                                            <div>
                                                {
                                                    adDetails?.userPost?.user_post_ad?.description ?
                                                        <div className="mt-0">
                                                            <h6>{adDetails?.userPost?.user_post_ad?.description}</h6>
                                                        </div> : null
                                                }
                                            </div>
                                            <div>
                                                <Link to={adDetails?.userPost?.user_post_ad?.ad_link} target='_blank noopener,noreferrer'>
                                                    <button type="submit" className="btn btn-primary d-block w-20">
                                                        {adDetails?.userPost?.user_post_ad?.button_text}</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                showEditPostModal ?
                    <AdEditCard adPostId={slug} showEditPostModal={showEditPostModal}
                        handleClose={handleClose} refreshApi={getAdDetails} /> : null
            }
        </>
    )

}

export default AdsDetails;