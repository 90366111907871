import React, { useState, useEffect } from 'react'
import { Container, Card, Row, Col, Dropdown, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom'
import ProfileHeader from '../../../../components/profile-header'
import CustomToggle from '../../../../components/dropdowns'
import ShareOffcanvas from '../../../../components/share-offcanvas'
import LoaderModal from '../../../../components/loaderModal'
import { InView } from "react-intersection-observer";
import Moment from 'moment';
import { apiHandler } from '../../../../api/apihandler';
import { CLASS_DETAILS, MY_GRADE } from '../../../../api/constants'
import DOMPurify from "isomorphic-dompurify";

//image
import loader from '../../../../assets/images/page-img/page-load-loader.gif'
import header from '../../../../assets/images/page-img/profile-bg7.jpg'

// fullcalender
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from "@fullcalendar/list"

import user1 from '../../../../assets/images/user/05.jpg'
import user2 from '../../../../assets/images/user/06.jpg'
import user3 from '../../../../assets/images/user/07.jpg'
import user4 from '../../../../assets/images/user/08.jpg'
import user5 from '../../../../assets/images/user/09.jpg'
import user6 from '../../../../assets/images/user/10.jpg'
import user7 from '../../../../assets/images/user/11.jpg'
import user8 from '../../../../assets/images/user/12.jpg'
import NoDataFound from '../../../../components/noDataFound'
import ClassBuyPopup from '../../../../components/classes/classBuyPopup'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import GradeBuyPopup from '../../../../components/classes/gradeBuyPopup'

const MyGrade = () => {
    let profileData = (useSelector((state) => state.setting.setting.user_data))
    profileData = profileData ? JSON.parse(profileData) : "";
    const navigate = useNavigate();
    const [myGradeData, setMyGradeData] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showBuy, setShowBuy] = useState(false);

    useEffect(() => {
        getMyGradeApi()
    }, []);

    async function getMyGradeApi() {
        setShowLoader(true)
        try {
            const res = await apiHandler(MY_GRADE, "POST")
            if (res.data?.response?.status === 200) {
                setMyGradeData(res?.data?.data?.grade)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false)
        }
    }

    function tConv24(time24) {
        var ts = time24;
        var H = +ts.substr(0, 2);
        var h = (H % 12) || 12;
        h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? " AM" : " PM";
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    };

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            {/* <ProfileHeader img={header} title="Events" /> */}
            <div id="content-page" className="content-page">
                <Container>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className=" profile-page p-0">
                                    <div className="profile-header">
                                        <div className="position-relative">
                                            <img loading="lazy" src={myGradeData?.banner_image ? myGradeData?.banner_image : header} alt="profile-bg" className="rounded img-fluid cover"
                                                style={{ height: "100%", width: "100%", objectFit: "fill", maxHeight: "250px" }} />
                                        </div>
                                        {
                                            localStorage.getItem("eventIsPast") === "true" ?
                                                <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative" style={{ minHeight: "60px" }}>
                                                </div> :
                                                <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                                                </div>
                                        }
                                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                            <div className="group-info d-flex align-items-center">
                                                <div className="info ms-3">
                                                    <h3>{myGradeData?.grade_name?.name}</h3>
                                                    <p className="mb-0"><i className="ri-calendar-2-line pe-2"></i>
                                                        {myGradeData?.session}</p>
                                                    {
                                                        myGradeData?.user_grade_transactions?.length > 0 ? null:
                                                            <button type="submit" className="btn btn-primary mb-2 me-1 mt-1"
                                                                onClick={() => {
                                                                    setShowBuy(true)
                                                                }}
                                                            >Pay Now</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-0 me-2">
                                                <h3>{"$" + myGradeData?.grade_name?.fees} {myGradeData?.grade_name?.payment_type === 0 ? "/ W" : myGradeData?.grade_name?.payment_type === 1 ? "/ M" : ""}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Transaction History</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        myGradeData?.user_grade_transactions?.length > 0 ? myGradeData?.user_grade_transactions.map((elm, i) => (
                                            <div className="mt-0 mb-3">
                                                <div className="d-flex justify-content-between">
                                                    <h5 className="mb-0 d-inline-block btn-primary">{Moment(elm?.payment_date).format('yyyy-MMM-DD')}</h5>
                                                    <h5 className="mb-0 d-inline-block btn-primary">{"$" + elm?.price}</h5>
                                                </div>
                                                <h6 className="mb-0 d-inline-block w-100">{"Txn :  " + elm?.transaction_id}</h6>
                                                <h6 className="mb-0 d-inline-block w-100"><i className="ri-currency-line pe-2"></i>{myGradeData?.grade_name?.payment_type === 0 ? "Weekly Payment" :
                                                    myGradeData?.grade_name?.payment_type === 1 ? "Monthly Payment" : "One Time Payment"}</h6>
                                            </div>
                                        )) :
                                            <NoDataFound />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Grade Classes</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        myGradeData?.grade_name?.grade_classes?.length > 0 ? myGradeData?.grade_name?.grade_classes.map((elm, i) => (
                                            <Button variant="primary" className="rounded-pill mb-2 me-3" onClick={()=>{
                                                navigate(`/dashboards/app/class-detail/${elm?.class_name?.slug}`)
                                            }}>{elm?.class_name?.name}</Button>
                                        )) :
                                            <NoDataFound />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                showBuy ?
                    <GradeBuyPopup elm={myGradeData} showBuy={showBuy} setShowBuy={setShowBuy} /> : null
            }
        </>
    )
}

export default MyGrade