import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Loader from "react-js-loader";


const LoaderModal = ({ showLoader }) => {

    useEffect(() => {
    }, [])


    return (
        <Modal show={showLoader} size="sm" style={{ paddingTop: "200px", background: "#00000070" }}>
            <Loader type="spinner-default" bgColor={"#5c518b"} color={"#a8afb5b8"} title={"Please Wait..."} size={100} />
        </Modal>
    )
}

export default LoaderModal
