import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal, ModalFooter } from 'react-bootstrap'
import Card from '../Card'
import { apiHandler } from '../../api/apihandler';
import { MY_TICKET_CANCEL } from "../../api/constants";
import CustomToggle from '../../components/dropdowns'
import { Link, useNavigate, Route } from 'react-router-dom'
import MyTicketsCard from './myTicketsCard'
import LoaderModal from '../loaderModal'
import Swal from 'sweetalert2'


const MyTicketsList = ({ elm, getMyTicketsList, isPast }) => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
    }, [])

    async function cancelAllTicketApi() {
        if (localStorage.getItem("uzoneAuthToken")) {
            setShowLoader(true)
            try {
                const res = await apiHandler(MY_TICKET_CANCEL, "POST", {
                    event_id: elm?.event_id,
                    ticket_order_id: elm?.eventTicketNos?.event_ticket_order_id
                })
                if (res.data?.response?.status === 200) {
                    Swal.fire({
                        title: 'Success',
                        text: res?.response?.data?.data?.message,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    getMyTicketsList()
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.response?.data?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
            } catch (error) {
                console.error(error);
            }
            finally {
                setShowLoader(false)
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    return (
        <>
            <LoaderModal showLoader={showLoader} />
            <div>
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title" style={{ cursor: "pointer" }} onClick={() => {
                                navigate(`/dashboards/app/event-detail/${elm?.event?.slug}`)
                            }}>{elm?.event?.name} Tickets</h4>
                        </div>
                        <div className="card-post-toolbar">
                            <h5><Link to="#"
                                onClick={() => {
                                    Swal.fire({
                                        title: 'Warning!',
                                        text: `Are you sure, want to cancel all tickets?`,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        showConfirmButton: true,
                                        cancelButtonText: 'Cancel',
                                        confirmButtonText: 'Cancel Ticket',
                                        confirmButtonColor: "#5c518b"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            cancelAllTicketApi()
                                        }
                                    })
                                }}>Cancel All</Link></h5>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="d-grid gap-3 d-grid-template-1fr-19 mb-3 mt-2">
                            {
                                elm?.eventTicketNos.map((data, i) => (
                                    <MyTicketsCard elm={elm} eventTicketNos={data} getMyTicketsList={getMyTicketsList}
                                        key={data?.id} isPast={isPast} />
                                ))
                            }
                        </div>

                    </Card.Body>
                </Card>

            </div>
        </>
    )
}

export default MyTicketsList
